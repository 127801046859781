import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PhoneIcon from "@material-ui/icons/Phone";
import Typography from "@material-ui/core/Typography";
import EmailIcon from "@material-ui/icons/Email";
import React from "react";
import Paper from "@material-ui/core/Paper";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(12)}px ${theme.spacing(4)}px`,
    alignItems: "center",
  },
  list: {
    marginTop: theme.spacing(2),
  },
  gridItems: {
    textAlign: "center",
  },
}));

const ContactUs = () => {
  const classes = useStyle();
  const displayValues: any = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6,
  };
  return (
    <Paper>
      <Grid id="contactUs" container spacing={1} className={classes.root}>
        <Grid
          item
          className={classes.gridItems}
          xs={displayValues.xs}
          sm={displayValues.sm}
          lg={displayValues.lg}
          md={displayValues.md}
          xl={displayValues.xl}
        >
          <Typography variant="h3">BRUNDAM</Typography>
        </Grid>
        <Grid
          item
          className={classes.gridItems}
          xs={displayValues.xs}
          sm={displayValues.sm}
          lg={displayValues.lg}
          md={displayValues.md}
          xl={displayValues.xl}
        >
          <List dense className={classes.list}>
            <ListItem>
              <ListItemIcon>
                <EmailIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ variant: "h6" }}
                primary="nettenmehra@gmail.com"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PhoneIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ variant: "h6" }}
                primary="+49 170 6000201"
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ContactUs;
