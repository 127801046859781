import React, { useState } from "react";
import AppBar from "./AppBar";
import Drawer from "./Drawer";

const Layout = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <>
      <AppBar handleDrawerClose={() => setIsDrawerOpen(!isDrawerOpen)} />
      <Drawer
        isDraweOpen={isDrawerOpen}
        handleDrawerClose={() => setIsDrawerOpen(!isDrawerOpen)}
      />
    </>
  );
};

export default Layout;
