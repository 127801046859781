import { makeStyles } from "@material-ui/core";
import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { zoomInTime } from "../constants";
import { routes } from "../routes";
import IconButton from "@material-ui/core/IconButton";
import { NavHashLink as Link } from "react-router-hash-link";
import MenuIcon from "@material-ui/icons/Menu";
import { colors } from "../themeOverride";
import HideOnScroll from "./HideOnScroll";
import { useTrail, animated } from "react-spring";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-end",
    },
  },
  icon: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      width: "100vw",
    },
  },
  link: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    ...theme.typography.h6,
    marginRight: theme.spacing(4),
  },
  menuLink: {
    ...theme.typography.h6,
  },
  navLink: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    textDecoration: "none",
    color: colors.white,
    "&.active": {
      textDecoration: "overline",
    },
  },
}));

interface Props {
  handleDrawerClose: () => void;
}
const AppBar = ({ handleDrawerClose }: Props) => {
  const classes = useStyle();

  const trail = useTrail(routes.length, {
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: zoomInTime },
  });

  const links = trail.map((style, i) => (
    <animated.div style={style} key={i}>
      <Link smooth exact className={classes.navLink} to={routes[i].anchor}>
        <Typography component="div" className={classes.link}>
          {routes[i].label}
        </Typography>
      </Link>
    </animated.div>
  ));

  return (
    <>
      <HideOnScroll>
        <MuiAppBar position="fixed">
          <Toolbar className={classes.root}>
            <>
              <IconButton
                className={classes.icon}
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerClose}
              >
                <MenuIcon />
              </IconButton>
              {links}
            </>
          </Toolbar>
        </MuiAppBar>
      </HideOnScroll>
    </>
  );
};

export default AppBar;
