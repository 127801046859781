import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    overflowX: "scroll",
  },
  header: {
    marginBottom: theme.spacing(1),
  },
}));

const Datenschutz = () => {
  const classes = useStyle();
  return (
    <Paper className={classes.root}>
      <Typography className={classes.header} variant="h4" component="div">
        Datenschutz&shy;erkl&auml;rung
      </Typography>
      <Typography variant="h5" component="div">
        1. Datenschutz auf einen Blick
      </Typography>
      <Typography variant="h6" component="div">
        Allgemeine Hinweise
      </Typography>
      <p>
        Die folgenden Hinweise geben einen einfachen &Uuml;berblick
        dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
        diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
        denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
        Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie
        unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
      </p>
      <Typography variant="h6" component="div">
        Datenerfassung auf dieser Website
      </Typography>
      <Typography variant="h5" component="div">
        Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
      </Typography>
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt
        &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
        Datenschutzerkl&auml;rung entnehmen.
      </p>
      <Typography variant="h5" component="div">
        Wie erfassen wir Ihre Daten?
      </Typography>
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in
        ein Kontaktformular eingeben.
      </p>
      <p>
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch
        der Website durch unsere IT-Systeme erfasst. Das sind vor allem
        technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder
        Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
        automatisch, sobald Sie diese Website betreten.
      </p>
      <Typography variant="h5" component="div">
        Wof&uuml;r nutzen wir Ihre Daten?
      </Typography>
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse
        Ihres Nutzerverhaltens verwendet werden.
      </p>
      <Typography variant="h5" component="div">
        Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?
      </Typography>
      <p>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
        Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen
        Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung
        oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung
        zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung
        jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
        Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der
        Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren
        steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen
        Aufsichtsbeh&ouml;rde zu.
      </p>
      <p>
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
        sich jederzeit an uns wenden.
      </p>
      <Typography variant="h6" component="div">
        Analyse-Tools und Tools von Dritt&shy;anbietern
      </Typography>
      <p>
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
        ausgewertet werden. Das geschieht vor allem mit sogenannten
        Analyseprogrammen.
      </p>
      <p>
        Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der
        folgenden Datenschutzerkl&auml;rung.
      </p>
      <Typography variant="h5" component="div">
        2. Hosting und Content Delivery Networks (CDN)
      </Typography>
      <Typography variant="h6" component="div">
        Externes Hosting
      </Typography>
      <p>
        Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
        Die personenbezogenen Daten, die auf dieser Website erfasst werden,
        werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v.
        a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
        Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten,
        die &uuml;ber eine Website generiert werden, handeln.
      </p>
      <p>
        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung
        gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6 Abs.
        1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
        effizienten Bereitstellung unseres Online-Angebots durch einen
        professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
      </p>
      <p>
        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
        Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere
        Weisungen in Bezug auf diese Daten befolgen.
      </p>
      <p>Wir setzen folgenden Hoster ein:</p>
      <p>
        netcup GmbH
        <br />
        Daimlerstra&szlig;e 25
        <br />
        D-76185 Karlsruhe
      </p>
      <Typography variant="h5" component="div">
        3. Allgemeine Hinweise und Pflicht&shy;informationen
      </Typography>
      <Typography variant="h6" component="div">
        Datenschutz
      </Typography>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
        Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich
        und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerkl&auml;rung.
      </p>
      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
        Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
        wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem
        Zweck das geschieht.
      </p>
      <p>
        Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
        (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
        aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff
        durch Dritte ist nicht m&ouml;glich.
      </p>
      <Typography variant="h6" component="div">
        Hinweis zur verantwortlichen Stelle
      </Typography>
      <p>
        Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
        Website ist:
      </p>
      <p>
        Netten Mehra
        <br />
        Am Richtsberg 4<br />
        35039 Marburg
      </p>
      <p>
        Telefon: 01706000201
        <br />
        E-Mail: nettenmehra@gmail.com
      </p>
      <p>
        Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person,
        die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel
        der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
        E-Mail-Adressen o. &Auml;.) entscheidet.
      </p>
      <Typography variant="h6" component="div">
        Speicherdauer
      </Typography>
      <p>
        Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere
        Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
        uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt. Wenn
        Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
        Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
        gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen&nbsp;
        Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben
        (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
        letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall dieser
        Gr&uuml;nde.
      </p>
      <Typography variant="h6" component="div">
        Hinweis zur Datenweitergabe in die USA
      </Typography>
      <p>
        Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in
        den USA eingebunden. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre
        personenbezogenen Daten an die US-Server der jeweiligen Unternehmen
        weitergegeben werden. Wir weisen darauf hin, dass die USA kein sicherer
        Drittstaat im Sinne des EU-Datenschutzrechts sind. US-Unternehmen sind
        dazu verpflichtet, personenbezogene Daten an Sicherheitsbeh&ouml;rden
        herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich
        vorgehen k&ouml;nnten. Es kann daher nicht ausgeschlossen werden, dass
        US-Beh&ouml;rden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen
        Daten zu &Uuml;berwachungszwecken verarbeiten, auswerten und dauerhaft
        speichern. Wir haben auf diese Verarbeitungst&auml;tigkeiten keinen
        Einfluss.
      </p>
      <Typography variant="h6" component="div">
        Widerruf Ihrer Einwilligung zur Datenverarbeitung
      </Typography>
      <p>
        Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
        ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
        bereits erteilte Einwilligung jederzeit widerrufen. Die
        Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
      </p>
      <Typography variant="h6" component="div">
        Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
        sowie gegen Direktwerbung (Art. 21 DSGVO)
      </Typography>
      <p>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
        DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE SICH
        AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&Uuml;R
        EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE
        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN
        WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
        SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE
        F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND
        FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER
        GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON
        RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
      </p>
      <p>
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
        BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
        VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
        DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
        SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
        WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
        MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
        ABS. 2 DSGVO).
      </p>
      <Typography variant="h6" component="div">
        Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde
      </Typography>
      <p>
        Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen
        ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem
        Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes
        oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das
        Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher
        oder gerichtlicher Rechtsbehelfe.
      </p>
      <Typography variant="h6" component="div">
        Recht auf Daten&shy;&uuml;bertrag&shy;barkeit
      </Typography>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich
        oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format
        aush&auml;ndigen zu lassen. Sofern Sie die direkte &Uuml;bertragung der
        Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
        soweit es technisch machbar ist.
      </p>
      <Typography variant="h6" component="div">
        Auskunft, L&ouml;schung und Berichtigung
      </Typography>
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck
        der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
        L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
        personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
      </p>
      <Typography variant="h6" component="div">
        Recht auf Einschr&auml;nkung der Verarbeitung
      </Typography>
      <p>
        Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
        jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der
        Verarbeitung besteht in folgenden F&auml;llen:
      </p>
      <ul>
        <li>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, ben&ouml;tigen wir in der Regel Zeit, um dies zu
          &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie
          das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </li>
        <li>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten
          unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
          L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.
        </li>
        <li>
          Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie
          sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
          Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
          L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </li>
        <li>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
          vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
          &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
        </li>
      </ul>
      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
        eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
        Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
        Geltendmachung, Aus&uuml;bung oder Verteidigung von
        Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
        nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
        wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder
        eines Mitgliedstaats verarbeitet werden.
      </p>
      <Typography variant="h5" component="div">
        4. Datenerfassung auf dieser Website
      </Typography>
      <Typography variant="h6" component="div">
        Cookies
      </Typography>
      <p>
        Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;.
        Cookies sind kleine Textdateien und richten auf Ihrem Endger&auml;t
        keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die
        Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente
        Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden
        nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies
        bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst
        l&ouml;schen&nbsp;oder eine automatische L&ouml;schung durch Ihren
        Webbrowser erfolgt.
      </p>
      <p>
        Teilweise k&ouml;nnen auch Cookies von Drittunternehmen auf Ihrem
        Endger&auml;t gespeichert werden, wenn Sie unsere Seite betreten
        (Third-Party-Cookies). Diese erm&ouml;glichen uns oder Ihnen die Nutzung
        bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur
        Abwicklung von Zahlungsdienstleistungen).
      </p>
      <p>
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
        notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren
        w&uuml;rden (z.B. die Warenkorbfunktion oder die Anzeige von Videos).
        Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten&nbsp;oder
        Werbung anzuzeigen.
      </p>
      <p>
        Cookies, die zur Durchf&uuml;hrung des elektronischen
        Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
        bestimmter, von Ihnen erw&uuml;nschter Funktionen (funktionale Cookies,
        z. B. f&uuml;r die Warenkorbfunktion) oder zur Optimierung der Website
        (z.B. Cookies zur Messung des Webpublikums) erforderlich sind, werden
        auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine
        andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine
        Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die
        Speicherung der betreffenden Cookies ausschlie&szlig;lich auf Grundlage
        dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
        jederzeit widerrufbar.
      </p>
      <p>
        Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das
        Setzen von Cookies informiert werden und Cookies nur im Einzelfall
        erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder
        generell ausschlie&szlig;en sowie das automatische L&ouml;schen der
        Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der
        Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website
        eingeschr&auml;nkt sein.
      </p>
      <p>
        Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
        werden, werden wir Sie hier&uuml;ber im Rahmen dieser
        Datenschutzerkl&auml;rung gesondert informieren und ggf. eine
        Einwilligung abfragen.
      </p>
      <Typography variant="h5" component="div">
        5. Soziale Medien
      </Typography>
      <Typography variant="h6" component="div">
        Social-Media-Plugins mit Shariff
      </Typography>
      <p>
        Auf dieser Website werden Plugins von sozialen Medien verwendet
        (z.&nbsp;B. Facebook, Twitter, Instagram, Pinterest, XING, LinkedIn,
        Tumblr).
      </p>
      <p>
        Die Plugins k&ouml;nnen Sie in der Regel anhand der jeweiligen
        Social-Media-Logos erkennen. Um den Datenschutz auf dieser Website zu
        gew&auml;hrleisten, verwenden wir diese Plugins nur zusammen mit der
        sogenannten &bdquo;Shariff&ldquo;-L&ouml;sung. Diese Anwendung
        verhindert, dass die auf dieser Website integrierten Plugins Daten schon
        beim ersten Betreten der Seite an den jeweiligen Anbieter
        &uuml;bertragen.
      </p>
      <p>
        Erst wenn Sie das jeweilige Plugin durch Anklicken der zugeh&ouml;rigen
        Schaltfl&auml;che aktivieren, wird eine direkte Verbindung zum Server
        des Anbieters hergestellt (Einwilligung). Sobald Sie das Plugin
        aktivieren, erh&auml;lt der jeweilige Anbieter die Information, dass Sie
        mit Ihrer IP-Adresse dieser Website besucht haben. Wenn Sie gleichzeitig
        in Ihrem jeweiligen Social-Media-Account (z.&nbsp;B. Facebook)
        eingeloggt sind, kann der jeweilige Anbieter den Besuch dieser Website
        Ihrem Benutzerkonto zuordnen.
      </p>
      <p>
        Das Aktivieren des Plugins stellt eine Einwilligung im Sinne des Art. 6
        Abs. 1 lit. a DSGVO dar. Diese Einwilligung k&ouml;nnen Sie jederzeit
        mit Wirkung f&uuml;r die Zukunft widerrufen.
      </p>
      {/*<Typography variant="h6" component="div">*/}
      {/*  Facebook Plugins (Like &amp; Share-Button)*/}
      {/*</Typography>*/}
      {/*<p>*/}
      {/*  Auf dieser Website sind Plugins des sozialen Netzwerks Facebook*/}
      {/*  integriert. Anbieter dieses Dienstes ist die Facebook Ireland Limited, 4*/}
      {/*  Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach*/}
      {/*  Aussage von Facebook jedoch auch in die USA und in andere*/}
      {/*  Drittl&auml;nder &uuml;bertragen.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem*/}
      {/*  &bdquo;Like-Button&ldquo; (&bdquo;Gef&auml;llt mir&ldquo;) auf dieser*/}
      {/*  Website. Eine &Uuml;bersicht &uuml;ber die Facebook Plugins finden Sie*/}
      {/*  hier:*/}
      {/*  <a*/}
      {/*    href="https://developers.facebook.com/docs/plugins/?locale=de_DE"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    https://developers.facebook.com/docs/plugins/?locale=de_DE*/}
      {/*  </a>*/}
      {/*  .*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Wenn Sie diese Website besuchen, wird &uuml;ber das Plugin eine direkte*/}
      {/*  Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt.*/}
      {/*  Facebook erh&auml;lt dadurch die Information, dass Sie mit Ihrer*/}
      {/*  IP-Adresse diese Website besucht haben. Wenn Sie den Facebook*/}
      {/*  &bdquo;Like-Button&ldquo; anklicken, w&auml;hrend Sie in Ihrem*/}
      {/*  Facebook-Account eingeloggt sind, k&ouml;nnen Sie die Inhalte dieser*/}
      {/*  Website auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den*/}
      {/*  Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf*/}
      {/*  hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der*/}
      {/*  &uuml;bermittelten Daten sowie deren Nutzung durch Facebook erhalten.*/}
      {/*  Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung*/}
      {/*  von Facebook unter:*/}
      {/*  <a*/}
      {/*    href="https://de-de.facebook.com/privacy/explanation"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    https://de-de.facebook.com/privacy/explanation*/}
      {/*  </a>*/}
      {/*  .*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Wenn Sie nicht w&uuml;nschen, dass Facebook den Besuch dieser Website*/}
      {/*  Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus*/}
      {/*  Ihrem Facebook-Benutzerkonto aus.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art. 6*/}
      {/*  Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse*/}
      {/*  an einer m&ouml;glichst umfangreichen Sichtbarkeit in den Sozialen*/}
      {/*  Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt*/}
      {/*  die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1*/}
      {/*  lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Die Daten&uuml;bertragung in die USA wird auf die*/}
      {/*  Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden*/}
      {/*  Sie hier:*/}
      {/*  <a*/}
      {/*    href="https://www.facebook.com/legal/EU_data_transfer_addendum"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    https://www.facebook.com/legal/EU_data_transfer_addendum*/}
      {/*  </a>*/}
      {/*  ,*/}
      {/*  <a*/}
      {/*    href="https://de-de.facebook.com/help/566994660333381"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    https://de-de.facebook.com/help/566994660333381*/}
      {/*  </a>*/}
      {/*  und*/}
      {/*  <a*/}
      {/*    href="https://www.facebook.com/policy.php"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    https://www.facebook.com/policy.php*/}
      {/*  </a>*/}
      {/*  .*/}
      {/*</p>*/}
      {/*<Typography variant="h6" component="div">*/}
      {/*  AMAZON PARTNERPROGRAMM*/}
      {/*</Typography>*/}
      {/*<p>*/}
      {/*  Die Betreiber dieser Website nehmen am Amazon EU- Partnerprogramm teil.*/}
      {/*  Auf dieser Website werden durch Amazon Werbeanzeigen und Links zur Seite*/}
      {/*  von Amazon.de eingebunden, an denen wir über Werbekostenerstattung Geld*/}
      {/*  verdienen können. Amazon setzt dazu Cookies oder vergleichbare*/}
      {/*  Wiedererkennungstechnologien (z.B. Device-Fingerprinting) ein, um die*/}
      {/*  Herkunft der Bestellungen nachvollziehen zu können. Dadurch kann Amazon*/}
      {/*  erkennen, dass Sie den Partnerlink auf dieser Website geklickt haben.*/}
      {/*  Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6*/}
      {/*  Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse*/}
      {/*  an der korrekten Berechnung seiner Affiliate-Vergütung. Sofern eine*/}
      {/*  entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur*/}
      {/*  Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf*/}
      {/*  Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit*/}
      {/*  widerrufbar. Weitere Informationen zur Datennutzung durch Amazon*/}
      {/*  erhalten Sie in der Datenschutzerklärung von Amazon:*/}
      {/*  https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401.*/}
      {/*</p>*/}

      <Typography variant="h5" component="div">
        6. Newsletter
      </Typography>
      <Typography variant="h6" component="div">
        Newsletter&shy;daten
      </Typography>
      <p>
        Wenn Sie den auf der Website angebotenen Newsletter beziehen
        m&ouml;chten, ben&ouml;tigen wir von Ihnen eine E-Mail-Adresse sowie
        Informationen, welche uns die &Uuml;berpr&uuml;fung gestatten, dass Sie
        der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des
        Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf
        freiwilliger Basis erhoben. Diese Daten verwenden wir
        ausschlie&szlig;lich f&uuml;r den Versand der angeforderten
        Informationen und geben diese nicht an Dritte weiter.
      </p>
      <p>
        Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten
        erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung (Art. 6
        Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der
        Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
        Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa &uuml;ber den
        &bdquo;Austragen&ldquo;-Link im Newsletter. Die
        Rechtm&auml;&szlig;igkeit der bereits erfolgten
        Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.
      </p>
      <p>
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
        Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns
        bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung
        des Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste
        gel&ouml;scht. Wir behalten uns vor, E-Mail-Adressen aus unserem
        Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten
        Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu l&ouml;schen oder zu
        sperren.
      </p>
      <p>
        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
        E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer
        Blacklist gespeichert, um k&uuml;nftige Mailings zu verhindern. Die
        Daten aus der Blacklist werden nur f&uuml;r diesen Zweck verwendet und
        nicht mit anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem
        Interesse als auch unserem Interesse an der Einhaltung der gesetzlichen
        Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne
        des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist
        zeitlich nicht befristet.
        <strong>
          Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre Interessen
          unser berechtigtes Interesse &uuml;berwiegen.
        </strong>
      </p>
      <Typography variant="h5" component="div">
        7. Plugins und Tools
      </Typography>
      {/*<Typography variant="h6" component="div">*/}
      {/*  YouTube mit erweitertem Datenschutz*/}
      {/*</Typography>*/}
      {/*<p>*/}
      {/*  Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist*/}
      {/*  die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow*/}
      {/*  Street, Dublin 4, Irland.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt*/}
      {/*  laut YouTube, dass YouTube keine Informationen &uuml;ber die Besucher*/}
      {/*  auf dieser Website speichert, bevor diese sich das Video ansehen. Die*/}
      {/*  Weitergabe von Daten an YouTube-Partner wird durch den erweiterten*/}
      {/*  Datenschutzmodus hingegen nicht zwingend ausgeschlossen. So stellt*/}
      {/*  YouTube &ndash; unabh&auml;ngig davon, ob Sie sich ein Video ansehen*/}
      {/*  &ndash; eine Verbindung zum Google DoubleClick-Netzwerk her.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine*/}
      {/*  Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem*/}
      {/*  YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn*/}
      {/*  Sie in Ihrem YouTube-Account eingeloggt sind, erm&ouml;glichen Sie*/}
      {/*  YouTube, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil*/}
      {/*  zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem*/}
      {/*  YouTube-Account ausloggen.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies*/}
      {/*  auf Ihrem Endger&auml;t speichern oder vergleichbare*/}
      {/*  Wiedererkennungstechnologien (z.B. Device-Fingerprinting) einsetzen. Auf*/}
      {/*  diese Weise kann YouTube Informationen &uuml;ber Besucher dieser Website*/}
      {/*  erhalten. Diese Informationen werden u. a. verwendet, um*/}
      {/*  Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern*/}
      {/*  und Betrugsversuchen vorzubeugen.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Gegebenenfalls k&ouml;nnen nach dem Start eines YouTube-Videos weitere*/}
      {/*  Datenverarbeitungsvorg&auml;nge ausgel&ouml;st werden, auf die wir*/}
      {/*  keinen Einfluss haben.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden*/}
      {/*  Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes*/}
      {/*  Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine*/}
      {/*  entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung*/}
      {/*  ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die*/}
      {/*  Einwilligung ist jederzeit widerrufbar.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Weitere Informationen &uuml;ber Datenschutz bei YouTube finden Sie in*/}
      {/*  deren Datenschutzerkl&auml;rung unter:*/}
      {/*  <a*/}
      {/*    href="https://policies.google.com/privacy?hl=de"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    https://policies.google.com/privacy?hl=de*/}
      {/*  </a>*/}
      {/*  .*/}
      {/*</p>*/}
      {/*<Typography variant="h6" component="div">*/}
      {/*  Vimeo*/}
      {/*</Typography>*/}
      {/*<p>*/}
      {/*  Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die*/}
      {/*  Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Wenn Sie eine unserer mit einem Vimeo-Video ausgestatteten Seiten*/}
      {/*  besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt.*/}
      {/*  Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie*/}
      {/*  besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Dies gilt auch dann,*/}
      {/*  wenn Sie nicht bei Vimeo eingeloggt sind oder keinen Account bei Vimeo*/}
      {/*  besitzen. Die von Vimeo erfassten Informationen werden an den*/}
      {/*  Vimeo-Server in den USA &uuml;bermittelt.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, erm&ouml;glichen Sie*/}
      {/*  Vimeo, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil*/}
      {/*  zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem*/}
      {/*  Vimeo-Account ausloggen.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Zur Wiedererkennung der Websitebesucher verwendet Vimeo Cookies bzw.*/}
      {/*  vergleichbare Wiedererkennungstechnologien (z.B. Device-Fingerprinting).*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden*/}
      {/*  Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes*/}
      {/*  Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine*/}
      {/*  entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung*/}
      {/*  ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die*/}
      {/*  Einwilligung ist jederzeit widerrufbar.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Die Daten&uuml;bertragung in die USA wird auf die*/}
      {/*  Standardvertragsklauseln der EU-Kommission sowie nach Aussage von Vimeo*/}
      {/*  auf &bdquo;berechtigte Gesch&auml;ftsinteressen&ldquo; gest&uuml;tzt.*/}
      {/*  Details finden Sie hier:*/}
      {/*  <a*/}
      {/*    href="https://vimeo.com/privacy"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    https://vimeo.com/privacy*/}
      {/*  </a>*/}
      {/*  .*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der*/}
      {/*  Datenschutzerkl&auml;rung von Vimeo unter:*/}
      {/*  <a*/}
      {/*    href="https://vimeo.com/privacy"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    https://vimeo.com/privacy*/}
      {/*  </a>*/}
      {/*  .*/}
      {/*</p>*/}
      <Typography variant="h6" component="div">
        Google Web Fonts
      </Typography>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
        einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
      </p>
      <p>
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
        Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
        dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen
        wurde. Die Nutzung von Google WebFonts erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
        an der einheitlichen Darstellung des Schriftbildes auf seiner Website.
        Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine
        Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung
        ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine
        Standardschrift von Ihrem Computer genutzt.
      </p>
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://developers.google.com/fonts/faq
        </a>
        und in der Datenschutzerkl&auml;rung von Google:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
        .
      </p>
      <Typography variant="h6" component="div">
        Font Awesome
      </Typography>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten und
        Symbolen Font Awesome. Anbieter ist die Fonticons, Inc., 6 Porter Road
        Apartment 3R, Cambridge, Massachusetts, USA.
      </p>
      <p>
        Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Fonts
        in ihren Browsercache, um Texte, Schriftarten und Symbole korrekt
        anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete Browser
        Verbindung zu den Servern von Font Awesome aufnehmen. Hierdurch erlangt
        Font Awesome Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese
        Website aufgerufen wurde. Die Nutzung von Font Awesome erfolgt auf
        Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes
        Interesse an der einheitlichen Darstellung des Schriftbildes auf unserer
        Website. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B.
        eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung
        ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Wenn Ihr Browser Font Awesome nicht unterst&uuml;tzt, wird eine
        Standardschrift von Ihrem Computer genutzt.
      </p>
      <p>
        Weitere Informationen zu Font Awesome finden Sie&nbsp;und in der
        Datenschutzerkl&auml;rung von Font Awesome unter:
        <a
          href="https://fontawesome.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://fontawesome.com/privacy
        </a>
        .
      </p>
      <Typography variant="h6" component="div">
        MyFonts
      </Typography>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Web Fonts von MyFonts Inc., 600 Unicorn Park Drive, Woburn,
        Massachusetts 01801 USA (nachfolgend: MyFonts). Hierbei handelt es sich
        um Schriftarten, die beim Aufrufen unserer Website in Ihren Browser
        geladen werden, um ein einheitliches Schriftbild bei der
        Webseitendarstellung zu gew&auml;hrleisten.
      </p>
      <p>
        Die Fonts sind lokal installiert. Eine Verbindung zu Servern von MyFonts
        findet dabei nicht statt. Der Einsatz der Fonts erfolgt auf Grundlage
        unseres berechtigten Interesses an einer einheitlichen Darstellung
        unserer Website (Art. 6 Abs. 1 lit. f DSGVO).
      </p>
      <Typography variant="h6" component="div">
        Google Maps
      </Typography>
      <p>
        Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google
        Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street,
        Dublin 4, Irland.
      </p>
      <p>
        Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
        IP-Adresse zu speichern. Diese Informationen werden in der Regel an
        einen Server von Google in den USA &uuml;bertragen und dort gespeichert.
        Der Anbieter dieser Seite hat keinen Einfluss auf diese
        Daten&uuml;bertragung.
      </p>
      <p>
        Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit
        der von uns auf der Website angegebenen Orte. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
        Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
        Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO; die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Die Daten&uuml;bertragung in die USA wird auf die
        Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden
        Sie hier:
        <a
          href="https://privacy.google.com/businesses/gdprcontrollerterms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://privacy.google.com/businesses/gdprcontrollerterms/
        </a>
        und
        <a
          href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
        </a>
        .
      </p>
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerkl&auml;rung von Google:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
        .
      </p>
      <Typography variant="h6" component="div">
        Google Analytics
      </Typography>
      <p>
        Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse
        an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres
        Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google
        Analytics, einen Webanalysedienst der Google LLC („Google“) ein. Google
        verwendet Cookies. Die durch das Cookie erzeugten Informationen über
        Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an
        einen Server von Google in den USA übertragen und dort gespeichert.
        Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
        hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten
        (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).
        Google wird diese Informationen in unserem Auftrag benutzen, um die
        Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um Reports
        über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen
        und um weitere, mit der Nutzung dieses Onlineangebotes und der
        Internetnutzung verbundene Dienstleistungen, uns gegenüber zu erbringen.
        Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der
        Nutzer erstellt werden. Wir setzen Google Analytics nur mit aktivierter
        IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von
        Google innerhalb von Mitgliedstaaten der Europäischen Union oder in
        anderen Vertragsstaaten des Abkommens über den Europäischen
        Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse
        an einen Server von Google in den USA übertragen und dort gekürzt. Die
        von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt. Die Nutzer können die
        Speicherung der Cookies durch eine entsprechende Einstellung ihrer
        Browser-Software verhindern; die Nutzer können darüber hinaus die
        Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des
        Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser
        Daten durch Google verhindern, indem sie das unter folgendem Link
        verfügbare Browser-Plugin herunterladen und installieren:
        http://tools.google.com/dlpage/gaoptout?hl=de. Weitere Informationen zur
        Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten,
        erfahren Sie in der Datenschutzerklärung von Google
        (https://policies.google.com/technologies/ads) sowie in den
        Einstellungen für die Darstellung von Werbeeinblendungen durch Google
        (https://adssettings.google.com/authenticated). Die personenbezogenen
        Daten der Nutzer werden nach 14 Monaten gelöscht oder anonymisiert.
      </p>
      {/*<Typography variant="h6" component="div">*/}
      {/*  Google AdSense*/}
      {/*</Typography>*/}
      {/*<p>*/}
      {/*  Unsere Website nutzt Google AdSense, einen Online-Werbedienst der Google*/}
      {/*  LLC („Google“). Google AdSense verwendet Cookies und Web Beacons. Durch*/}
      {/*  Web Beacons können Informationen wie der Besucherverkehr auf den Seiten*/}
      {/*  dieses Angebots ausgewertet werden. Die durch Cookies und Web Beacons*/}
      {/*  erzeugten Informationen über die Benutzung dieser Website*/}
      {/*  (einschließlich der IP-Adresse der Nutzer) und Auslieferung von*/}
      {/*  Werbeformaten werden an einen Server von Google in den USA übertragen*/}
      {/*  und dort gespeichert. Diese Informationen können von Google an*/}
      {/*  Vertragspartner von Google weitergegeben werden. Die im Rahmen von*/}
      {/*  Google AdSense erfolgenden Verarbeitungen sind aufgrund Ihrer*/}
      {/*  Einwilligung nach Art. 6 Abs. 1 lit. a) DS-GVO gerechtfertigt. Nähere*/}
      {/*  Informationen zu Datenschutz und Cookies für Werbung bei Google AdSense*/}
      {/*  sind in der Datenschutzerklärung von Google, insbesondere unter den*/}
      {/*  folgenden Links zu finden:www.google.de/policies/privacy/partners/;*/}
      {/*  www.google.de/intl/de/policies/technologies/ads.*/}
      {/*</p>*/}
      {/*<Typography variant="h6" component="div">*/}
      {/*  OpenStreetMap*/}
      {/*</Typography>*/}
      {/*<p>*/}
      {/*  Wir nutzen den Kartendienst von OpenStreetMap (OSM). Anbieterin ist die*/}
      {/*  Open-Street-Map Foundation (OSMF), 132 Maney Hill Road, Sutton*/}
      {/*  Coldfield, West Midlands, B72 1JU, United Kingdom.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Wenn Sie eine Website besuchen, auf der OpenStreetMap eingebunden ist,*/}
      {/*  werden u. a. Ihre IP-Adresse und weitere Informationen &uuml;ber Ihr*/}
      {/*  Verhalten auf dieser Website an die OSMF weitergeleitet. OpenStreetMap*/}
      {/*  speichert hierzu unter Umst&auml;nden Cookies in Ihrem Browser oder*/}
      {/*  setzt vergleichbare Wiedererkennungstechnologien ein.*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Ferner kann Ihr Standort erfasst werden, wenn Sie dies in Ihren*/}
      {/*  Ger&auml;teeinstellungen &ndash; z.&nbsp;B. auf Ihrem Handy &ndash;*/}
      {/*  zugelassen haben. Der Anbieter dieser Seite hat keinen Einfluss auf*/}
      {/*  diese Daten&uuml;bertragung. Details entnehmen Sie der*/}
      {/*  Datenschutzerkl&auml;rung von OpenStreetMap unter folgendem Link:*/}
      {/*  <a*/}
      {/*    href="https://wiki.osmfoundation.org/wiki/Privacy_Policy"*/}
      {/*    target="_blank"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    https://wiki.osmfoundation.org/wiki/Privacy_Policy*/}
      {/*  </a>*/}
      {/*  .*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Die Nutzung von OpenStreetMap erfolgt im Interesse einer ansprechenden*/}
      {/*  Darstellung unserer Online-Angebote und einer leichten Auffindbarkeit*/}
      {/*  der von uns auf der Website angegebenen Orte. Dies stellt ein*/}
      {/*  berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.*/}
      {/*  Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine*/}
      {/*  Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung*/}
      {/*  ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die*/}
      {/*  Einwilligung ist jederzeit widerrufbar.*/}
      {/*</p>*/}
      <Typography variant="h6" component="div">
        Google reCAPTCHA
      </Typography>
      <p>
        Wir nutzen &bdquo;Google reCAPTCHA&ldquo; (im Folgenden
        &bdquo;reCAPTCHA&ldquo;) auf dieser Website. Anbieter ist die Google
        Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street,
        Dublin 4, Irland.
      </p>
      <p>
        Mit reCAPTCHA soll &uuml;berpr&uuml;ft werden, ob die Dateneingabe auf
        dieser Website (z.&nbsp;B. in einem Kontaktformular) durch einen
        Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu
        analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand
        verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der
        Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA
        verschiedene Informationen aus (z.&nbsp;B. IP-Adresse, Verweildauer des
        Websitebesuchers auf der Website oder vom Nutzer get&auml;tigte
        Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google
        weitergeleitet.
      </p>
      <p>
        Die reCAPTCHA-Analysen laufen vollst&auml;ndig im Hintergrund.
        Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
        stattfindet.
      </p>
      <p>
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
        daran, seine Webangebote vor missbr&auml;uchlicher automatisierter
        Aussp&auml;hung und vor SPAM zu sch&uuml;tzen. Sofern eine entsprechende
        Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Weitere Informationen zu Google reCAPTCHA entnehmen Sie den
        Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen unter
        folgenden Links:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
        und
        <a
          href="https://policies.google.com/terms?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/terms?hl=de
        </a>
        .
      </p>
      <p>
        Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
      </p>
    </Paper>
  );
};

export default Datenschutz;
