import { makeStyles } from "@material-ui/core";
import React from "react";
import Logo from "../components/Logo";

const useStyle = makeStyles((theme) => ({
  root: {
    paddingTop: `${theme.spacing(3)}vh`,
    height: "100vh",
  },
}));
const Home = () => {
  const classes = useStyle();
  return (
    <div id="home" className={classes.root}>
      <Logo />
    </div>
  );
};

export default Home;
