import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { colors } from "../themeOverride";
import Dialog from "./Dialog";

const useStyle = makeStyles((theme) => ({
  root: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: colors.black,
      color: colors.white,
    },
  },
  okButton: {
    marginRight: theme.spacing(1),
  },
}));

const CookieSnackbar = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [isDatenschutzDisplayed, setIsDatenschutzDisplayed] = useState(false);
  const classes = useStyle();
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        className={classes.root}
        open={!isAccepted}
        message={
          <Typography variant="body1">
            Datenschutzerklärung zur Kenntnis genommen.
          </Typography>
        }
        action={
          <>
            <Button
              onClick={() => setIsAccepted(true)}
              color="inherit"
              variant="outlined"
              className={classes.okButton}
              size="small"
            >
              <Typography variant="body1">Ok</Typography>
            </Button>
            <Button
              onClick={() => {
                setIsAccepted(true);
                setIsDatenschutzDisplayed(true);
              }}
              variant="outlined"
              color="inherit"
              size="small"
            >
              <Typography variant="body1">Datenschutz</Typography>
            </Button>
          </>
        }
      />
      <Dialog
        isDatenschutz={true}
        open={isDatenschutzDisplayed}
        onClose={() => setIsDatenschutzDisplayed(false)}
      />
    </>
  );
};

export default CookieSnackbar;
