import { Route } from "./types";

export const routes: Route[] = [
  { label: "Home", anchor: "#home" },
  { label: "About Us", anchor: "#aboutUs" },
  { label: "Services", anchor: "#ourServices" },
  { label: "Projects", anchor: "#ourWork" },
  { label: "Team", anchor: "#ourTeam" },
  { label: "Contact Us", anchor: "#contactUs" },
];
