import makeStyles from "@material-ui/core/styles/makeStyles";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import CookieSnackbar from "./components/CookieSnackbar";
import Layout from "./components/Layout";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Footer from "./pages/Footer";
import Home from "./pages/Home";
import OurTeam from "./pages/OurTeam";
import OurWork from "./pages/OurWork";
import OurServices from "./pages/Services";

const useStyle = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
  },
}));

function App() {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Layout />
      <Toolbar />
      <Home />
      <AboutUs />
      <OurServices />
      <OurWork />
      <OurTeam />
      <ContactUs />
      <Footer />
      <CookieSnackbar />
    </div>
  );
}

export default App;
