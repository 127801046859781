import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import TeamMember from "../components/TeamMember";
import nettenImg from "../assets/images/NettenBg.png";
import drishtyImg from "../assets/images/DrishtyBg.png";
import { zoomInTime } from "../constants";
import Grid from "@material-ui/core/Grid";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(12)}px ${theme.spacing(4)}px`,
  },
}));

const OurTeam = () => {
  const classes = useStyle();
  const [isActiveImage, setIsActiveImage] = useState<{ [id: number]: boolean }>(
    {
      1: true,
      2: true,
    }
  );

  const displayValues: any = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6,
  };
  return (
    <div className={classes.root} id="ourTeam">
      <Grid container spacing={1}>
        <Grid
          item
          xs={displayValues.xs}
          sm={displayValues.sm}
          lg={displayValues.lg}
          md={displayValues.md}
          xl={displayValues.xl}
        >
          <VisibilitySensor
            active={isActiveImage[1]}
            onChange={(visible) => {
              if (visible) setIsActiveImage({ ...isActiveImage, [1]: false });
            }}
            partialVisibility
          >
            {({ isVisible }) => (
              <Spring
                delay={zoomInTime / 3}
                to={{
                  transform: isVisible
                    ? "translate3d(0,0,0)"
                    : "translate3d(-10px,0,0)",
                  opacity: isVisible ? 1 : 0,
                }}
                config={{ duration: zoomInTime }}
              >
                {(props) => (
                  <TeamMember
                    style={props}
                    imgSrc={nettenImg}
                    name="Netten Mehra"
                    position="Senior Software Developer"
                    profileUrl="https://www.linkedin.com/in/netten-mehra-b2ba084a"
                  />
                )}
              </Spring>
            )}
          </VisibilitySensor>
        </Grid>
        <Grid
          item
          xs={displayValues.xs}
          sm={displayValues.sm}
          lg={displayValues.lg}
          md={displayValues.md}
          xl={displayValues.xl}
        >
          <VisibilitySensor
            active={isActiveImage[2]}
            onChange={(visible) => {
              if (visible) setIsActiveImage({ ...isActiveImage, [2]: false });
            }}
            partialVisibility
          >
            {({ isVisible }) => (
              <Spring
                delay={zoomInTime / 3}
                to={{
                  transform: isVisible
                    ? "translate3d(0,0,0)"
                    : "translate3d(10px,0,0)",
                  opacity: isVisible ? 1 : 0,
                }}
                config={{ duration: zoomInTime }}
              >
                {(props) => (
                  <TeamMember
                    style={props}
                    imgSrc={drishtyImg}
                    name="Drishty Mehra"
                    position="Senior Software Developer"
                    profileUrl="https://www.linkedin.com/in/drishtykakkar"
                  />
                )}
              </Spring>
            )}
          </VisibilitySensor>
        </Grid>
      </Grid>
    </div>
  );
};

export default OurTeam;
