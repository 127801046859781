import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import Dialog from "../components/Dialog";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    display: "flex",
    "& .MuiTypography-root": {
      marginRight: theme.spacing(4),
      cursor: "pointer",
    },
  },
}));

const Footer = () => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [isDatenschutz, setIsDatenschutz] = useState(false);
  const handleDialog = (isDatenschutz: boolean) => {
    setIsDatenschutz(isDatenschutz);
    setOpen(true);
  };
  return (
    <div className={classes.root}>
      <Typography onClick={() => handleDialog(true)} variant="h6">
        Datenschutz
      </Typography>
      <Typography onClick={() => handleDialog(false)} variant="h6">
        Impressum
      </Typography>
      <Dialog
        isDatenschutz={isDatenschutz}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default Footer;
