import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import website1 from "../assets/images/website1.jpeg";
import website2 from "../assets/images/website2.jpeg";
import website3 from "../assets/images/website3.jpeg";
import website4 from "../assets/images/website4.jpeg";
import { workData, zoomInTime } from "../constants";
import { WorkData } from "../types";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: `${theme.spacing(16)}px ${theme.spacing(4)}px`,
    minHeight: `${theme.spacing(9)}vh`,
  },
  txt: {
    textAlign: "center",
  },
  img: {
    width: "90%",
    opacity: "0.8",
    cursor: "pointer",
  },
  gridContainer: {
    paddingTop: theme.spacing(12),
  },
  imgContainer: {
    textAlign: "center",
  },
}));

const OurWork = () => {
  const classes = useStyle();
  const [isActiveText, setIsActiveText] = useState<boolean>(true);
  const [isActiveImage, setIsActiveImage] = useState<{ [id: number]: boolean }>(
    {
      1: true,
      2: true,
      3: true,
      4: true,
    }
  );

  const getSource = (image: WorkData["image"]) => {
    switch (image) {
      default:
      case "website1":
        return website1;
      case "website2":
        return website2;
      case "website3":
        return website3;
      case "website4":
        return website4;
    }
  };
  return (
    <Paper id="ourWork" className={classes.root}>
      <VisibilitySensor
        active={isActiveText}
        onChange={(visible) => {
          if (visible) setIsActiveText(false);
        }}
        partialVisibility
      >
        {({ isVisible }) => (
          <Spring
            delay={zoomInTime / 3}
            to={{
              transform: isVisible
                ? "translate3d(0,0,0)"
                : "translate3d(-10px,0,0)",
              opacity: isVisible ? 1 : 0,
            }}
            config={{ duration: zoomInTime }}
          >
            {(props) => (
              <Typography style={props} variant="h5" className={classes.txt}>
                Till now, we have helped lot of clients to digitalize their
                ideas. We have developed websites, mobile applications, e-shops
                , games and many more things. Few of them are given below.
              </Typography>
            )}
          </Spring>
        )}
      </VisibilitySensor>
      <Grid container className={classes.gridContainer} spacing={2}>
        {workData.map(({ url, image }, index) => (
          <Grid
            className={classes.imgContainer}
            item
            key={url}
            xs={6}
            sm={6}
            md={6}
            lg={3}
            xl={3}
          >
            <VisibilitySensor
              active={isActiveImage[index + 1]}
              onChange={(visible) => {
                if (visible)
                  setIsActiveImage({ ...isActiveImage, [index + 1]: false });
              }}
              partialVisibility
            >
              {({ isVisible }) => (
                <Spring
                  delay={zoomInTime / 2}
                  to={{
                    transform: isVisible
                      ? "translate3d(0,0,0)"
                      : "translate3d(10px,0,0)",
                    opacity: isVisible ? 1 : 0,
                  }}
                  config={{ duration: zoomInTime * 1.5 }}
                >
                  {(props) => (
                    <img
                      alt=""
                      style={props}
                      className={classes.img}
                      src={getSource(image)}
                      onClick={() => window.open(url)}
                    />
                  )}
                </Spring>
              )}
            </VisibilitySensor>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default OurWork;
