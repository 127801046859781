import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

export const colors = {
  white: "#ffffff",
  black: "#000000",
};

let theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#303030",
    },
  },
  typography: {
    allVariants: {
      fontFamily: `'Playfair Display', sans-serif`,
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
  },
  props: {},
});

theme = responsiveFontSizes(theme);

export default theme;
