import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import LogoImg from "../assets/images/logo1.svg";
import { useSpring, animated } from "react-spring";
import { zoomInTime } from "../constants";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
  text: {
    [theme.breakpoints.between("xs", "sm")]: {
      ...theme.typography.h2,
    },
    [theme.breakpoints.between("md", "xl")]: {
      ...theme.typography.h1,
    },
  },
  container: {
    padding: theme.spacing(1),
  },
  img: {
    [theme.breakpoints.between("xs", "sm")]: {
      height: `${theme.spacing(2)}vh`,
    },
    [theme.breakpoints.between("md", "xl")]: {
      height: `${theme.spacing(3)}vh`,
    },
  },
}));

const Logo = () => {
  const classes = useStyle();
  // https://pixabay.com/vectors/ganesha-god-lord-elephant-160999/

  const textProps = useSpring({
    transform: "translate3d(0,0px,0)",
    opacity: 1,
    delay: zoomInTime,
    from: { transform: "translate3d(10px,0,0)", opacity: 0 },
    config: { duration: zoomInTime },
  });

  const imgProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: zoomInTime * 2,
    config: { duration: zoomInTime },
  });

  return (
    <div className={classes.root}>
      <animated.div style={imgProps} className={classes.container}>
        <img alt="logo" src={LogoImg} className={classes.img} />
      </animated.div>
      <animated.div style={textProps} className={classes.container}>
        <Typography className={classes.text} component="span">
          BRUNDAM
        </Typography>
      </animated.div>
    </div>
  );
};

export default Logo;
