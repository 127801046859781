import { WorkData } from "./types";

export const zoomInTime = 600;

export const workData: WorkData[] = [
  {
    image: "website1",
    url: "http://edumory.de/",
  },
  {
    image: "website2",
    url: "http://haarstylisten-giessen.de/bhs/index.html",
  },
  {
    image: "website3",
    url: "http://haarstylisten-giessen.de/hs/index.html",
  },
  {
    image: "website4",
    url: "https://bazaar-europe.com/",
  },
];
