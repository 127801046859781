import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ApartmentIcon from "@material-ui/icons/Apartment";
import HttpIcon from "@material-ui/icons/Http";
import LanguageIcon from "@material-ui/icons/Language";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import PolymerIcon from "@material-ui/icons/Polymer";
import ComputerIcon from "@material-ui/icons/Computer";
import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import { zoomInTime } from "../constants";

const useStyle = makeStyles((theme) => ({
  root: {
    minHeight: `${theme.spacing(6)}vh`,
    padding: `${theme.spacing(8)}px ${theme.spacing(2)}px`,

    "& .MuiTypography-root": {
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
  },
  icon: {
    "&.MuiSvgIcon-root": {
      fontSize: `${theme.spacing(1) / 1.8}rem`,
    },
  },
  item: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const OurServices = () => {
  const classes = useStyle();
  const [isActive, setIsActive] = useState<{ [id: number]: boolean }>({
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
  });
  const leistungen: { label: string; icon: any }[] = [
    {
      label: "Web Development",
      icon: <ComputerIcon className={classes.icon} />,
    },
    {
      label: "App Development",
      icon: <PhoneAndroidIcon className={classes.icon} />,
    },
    {
      label: "Digital Marketing",
      icon: <ApartmentIcon className={classes.icon} />,
    },
    {
      label: "Search Engine Optimization",
      icon: <LanguageIcon className={classes.icon} />,
    },
    {
      label: "Logo Designing",
      icon: <PolymerIcon className={classes.icon} />,
    },
    {
      label: "Hosting",
      icon: <HttpIcon className={classes.icon} />,
    },
  ];

  const displayValues: any = {
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6,
  };
  return (
    <div id="ourServices">
      <Grid container spacing={1} className={classes.root}>
        {leistungen.map((item, index) => (
          <VisibilitySensor
            active={isActive[index + 1]}
            onChange={(visible) => {
              if (visible) setIsActive({ ...isActive, [index + 1]: false });
            }}
            partialVisibility
          >
            {({ isVisible }) => (
              <Spring
                delay={zoomInTime / 2}
                to={{
                  transform: isVisible
                    ? "translate3d(0,0,0)"
                    : "translate3d(-10px,0,0)",
                  opacity: isVisible ? 1 : 0,
                }}
                config={{ duration: zoomInTime }}
              >
                {(props) => (
                  <Grid
                    style={props}
                    className={classes.item}
                    item
                    key={item.label}
                    xs={displayValues.xs}
                    sm={displayValues.sm}
                    lg={displayValues.lg}
                    md={displayValues.md}
                    xl
                  >
                    {item.icon}
                    <Typography variant="h5">{item.label}</Typography>
                  </Grid>
                )}
              </Spring>
            )}
          </VisibilitySensor>
        ))}
      </Grid>
    </div>
  );
};

export default OurServices;
