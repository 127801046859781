import { makeStyles } from "@material-ui/core";
import React from "react";
import MuiDrawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { NavHashLink as Link } from "react-router-hash-link";
import { routes } from "../routes";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import { colors } from "../themeOverride";

const useStyle = makeStyles((theme) => ({
  list: {
    width: `${theme.spacing(8)}vw`,
  },
  link: {
    ...theme.typography.h6,
  },
  navLink: {
    textDecoration: "none",
    color: colors.white,
    "&.active": {
      "& .MuiListItem-root": {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
}));

interface Props {
  isDraweOpen: boolean;
  handleDrawerClose: () => void;
}
const Drawer = ({ isDraweOpen, handleDrawerClose }: Props) => {
  const classes = useStyle();
  return (
    <MuiDrawer anchor="left" open={isDraweOpen} onClose={handleDrawerClose}>
      <Toolbar />
      <List className={classes.list}>
        {routes.map((link) => (
          <Link className={classes.navLink} smooth exact to={link.anchor}>
            <ListItem onClick={handleDrawerClose} key={link.label}>
              <Typography component="div" className={classes.link}>
                {link.label}
              </Typography>
            </ListItem>
          </Link>
        ))}
      </List>
    </MuiDrawer>
  );
};

export default Drawer;
