import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import AboutUsImage from "../assets/images/aboutUsImage.svg";
import VisibilitySensor from "react-visibility-sensor";
import { Spring } from "react-spring/renderprops";
import { zoomInTime } from "../constants";
import Paper from "@material-ui/core/Paper";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(8)}px ${theme.spacing(2)}px`,
  },
  container: { alignItems: "center", minHeight: `${theme.spacing(8)}vh` },
  txt: {
    ...theme.typography.h5,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    textAlign: "center",
  },
}));
// https://pixabay.com/vectors/mobile-devices-website-mockup-web-2017978/
const AboutUs = () => {
  const [isActiveText, setIsActiveText] = useState<boolean>(true);
  const [isActiveImage, setIsActiveImage] = useState<boolean>(true);
  const classes = useStyle();

  const displayValues: any = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6,
  };
  return (
    <Paper className={classes.root} id="aboutUs">
      <Grid container className={classes.container}>
        <Grid
          item
          xs={displayValues.xs}
          sm={displayValues.sm}
          lg={displayValues.lg}
          md={displayValues.md}
          xl={displayValues.xl}
        >
          <VisibilitySensor
            onChange={(visible) => {
              if (visible) setIsActiveText(false);
            }}
            active={isActiveText}
            partialVisibility
          >
            {({ isVisible }) => (
              <Spring
                delay={zoomInTime / 2}
                to={{
                  transform: isVisible
                    ? "translate3d(0,0,0)"
                    : "translate3d(-10px,0,0)",
                  opacity: isVisible ? 1 : 0,
                }}
                config={{ duration: zoomInTime }}
              >
                {(props) => (
                  <Typography
                    style={props}
                    className={classes.txt}
                    component="div"
                  >
                    In today's World, digitalization plays an important role.
                    Everything can be done online. Then why not give your dreams
                    some wings. We provide our clients with the best solutions
                    to digitalize their businesses. We are a team of software
                    developers. We develop websites, mobile applications, online
                    shops etc. We also help our clients with digital marketing
                    and many other solutions to boost up their businesses.
                  </Typography>
                )}
              </Spring>
            )}
          </VisibilitySensor>
        </Grid>
        <Grid
          item
          xs={displayValues.xs}
          sm={displayValues.sm}
          lg={displayValues.lg}
          md={displayValues.md}
          xl={displayValues.xl}
        >
          <VisibilitySensor
            partialVisibility
            onChange={(visible) => {
              if (visible) setIsActiveImage(false);
            }}
            active={isActiveImage}
          >
            {({ isVisible }) => (
              <Spring
                delay={zoomInTime / 2}
                to={{
                  transform: isVisible
                    ? "translate3d(0,0,0)"
                    : "translate3d(20px,0,0)",
                  opacity: isVisible ? 1 : 0,
                }}
                config={{ duration: zoomInTime }}
              >
                {(props) => <img style={props} src={AboutUsImage} alt="web" />}
              </Spring>
            )}
          </VisibilitySensor>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AboutUs;
