import { makeStyles } from "@material-ui/core";
import React from "react";
import MuiDialog from "@material-ui/core/Dialog";
import Datenschutz from "./Datenschutz";
import Impressums from "./Impressums";

interface Props {
  isDatenschutz: boolean;
  open: boolean;
  onClose: any;
}

const useStyle = makeStyles((theme) => ({
  root: {
    width: "80vw",
  },
}));

const Dialog = ({ isDatenschutz, open, onClose }: Props) => {
  const classes = useStyle();
  return (
    <MuiDialog className={classes.root} open={open} onClose={onClose}>
      {isDatenschutz ? <Datenschutz /> : <Impressums />}
    </MuiDialog>
  );
};

export default Dialog;
