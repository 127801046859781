import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import React from "react";

interface Props {
  style: any;
  imgSrc: string;
  name: string;
  email?: string;
  position: string;
  profileUrl: string;
}

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  profile: {
    display: "flex",
    marginTop: theme.spacing(1),
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      marginLeft: theme.spacing(1),
    },
  },
  name: {
    marginLeft: theme.spacing(1),
  },
  position: {
    marginTop: theme.spacing(2),
  },
  txtContainer: {
    textAlign: "center",
  },
  img: {
    height: `${theme.spacing(3.5)}vh`,
    opacity: 0.8,
  },
}));

const TeamMember = ({
  imgSrc,
  name,
  position,
  profileUrl,
  email,
  style,
}: Props) => {
  const classes = useStyle();
  return (
    <div className={classes.root} style={style}>
      <div>
        <img className={classes.img} src={imgSrc} alt="" />
      </div>
      <div className={classes.txtContainer}>
        <div className={classes.profile}>
          <LinkedInIcon
            fontSize="large"
            onClick={() => window.open(profileUrl)}
          />
          <Typography className={classes.name} variant="h4">
            {name}
          </Typography>
        </div>
        <Typography variant="h5" className={classes.position}>
          {position}
        </Typography>
        {email && <Typography variant="h5">{email}</Typography>}
      </div>
    </div>
  );
};

export default TeamMember;
